// Used in thirdparty-general.js, so export everything globally
/* eslint-disable no-undef,no-global-assign */
// noinspection JSConstantReassignment

// See UserCookieService.java for a list of values included in the userId cookie

// Keep the variables declared here in sync with globals.d.ts
// legacy variables
window.userId = '';
window.userName = '';
// userHasPW appears unused now
window.userHasPW = '';
window.userHash = '';
window.userType = '';
window.userHasProjectRefundChoices = '';
window.userHasPendingCheckPledges = '';

// recommended
window.userObj = {};

window.resetUserVariables = function () {
    userId = '';
    userName = '';
    userHasPW = '';
    userHash = '';
    userType = '';
    userHasProjectRefundChoices = '';
    userHasPendingCheckPledges = '';
    userObj = {};
};

window.setUpUserCookies = function () {
    resetUserVariables();
    if (isNonBlankCookie('userId')) {
        const userCookie = readCookie('userId');
        try {
            const decodedUserCookie = window.atob(userCookie);
            const obj = (JSON && JSON.parse(decodedUserCookie)) || $.parseJSON(userCookie);
            userId = obj.userId;
            // We URL-encode this string serverside, so we should URL-decode it here.
            userName = decodeURIComponent(obj.userName);
            userEmail = obj.email || '';
            userHasPW = obj.hasAccount;
            userHash = obj.hash;
            userType = obj.userType;
            userHasProjectRefundChoices = obj.hasProjectRefundChoices;
            userHasPendingCheckPledges = obj.hasPendingCheckPledges;
            userObj = obj;
        } catch (e) {
            const userCookieSplit = userCookie.split('##');
            if (userCookieSplit.length > 0) {
                userId = userCookieSplit[0];
                userName = userCookieSplit[1];
                userHasPW = userCookieSplit[2];
                userHash = userCookieSplit[3];
                userType = '';
                userObj = { userId, userName, userHasPW, userHash };
            }
        }

        // avoid rewriting the visitor cookie while we're in the cart
        if (location.href.toLowerCase().indexOf('cart') === -1) {
            if (userType == 'teacher') {
                createCookie('v', 'teacher', 365);
            } else if (userType == 'donor') {
                createCookie('v', 'donor', 365);
            }
        }

        if (userName != '') userName = unescape(userName);
        if (userName.length > 23) userName = userName.substring(0, 20) + '...';

        const usernameIsEmptyOrJustSpaces = (userName == null) || trim(userName) === '';
        const usernameIsANumber = !usernameIsEmptyOrJustSpaces && !isNaN(userName);
        if (usernameIsEmptyOrJustSpaces || usernameIsANumber || (qsParam.hasOwnProperty('pmaId') && qsParam.pmaId != '' && userId != qsParam.pmaId)) {
            eraseCookie('userId');
            resetUserVariables();
            removeFavorites();
        }
    }

    if (isNonBlankCookie('headerinfo')) {
        const headerinfo = JSON.parse(window.atob(readCookie('headerinfo')));
        if (headerinfo) {
            for (const key in headerinfo) {
                if (headerinfo.hasOwnProperty(key)) {
                    userObj[key] = headerinfo[key];
                }
            }
        }
    }

    $(window).trigger('userCookiesReset');
};

window.hasUserSetting = function (settingName) {
    let settings = readCookie('settings');
    if (settings != null) settings = settings.split('###');
    else settings = [];
    return $.inArray(settingName, settings) > -1;
};

window.isVIPDonor = function () {
    return !!(typeof userObj !== 'undefined' && typeof userObj.isVIP !== 'undefined' && userObj.isVIP);
};

window.isDAFDonor = function () {
    return !!(typeof userObj !== 'undefined' && typeof userObj.hasDAF !== 'undefined' && userObj.hasDAF);
};

window.isTipTopDonor = function () {
    return !!(typeof userObj !== 'undefined' && userObj.isTTD);
};

window.getUserType = function () {
    if (userObj.userType) {
        return userObj.userType;
    }
    if (readCookie('userType')) {
        return readCookie('userType');
    }
    if (readCookie('v')) {
        return readCookie('v');
    }
    return 'unknown';
};

window.getTopDonorOrUserType = function () {
    if (isVIPDonor()) {
        return 'topdonor';
    }
    return getUserType();
};

window.getUserSettings = function () {
    const settings = readCookie('settings');
    if (settings == null) return null;
    return settings.split('###');
};

window.addToUserSettings = function (settingName) {
    const settings = readCookie('settings');
    let settingsArray = [];
    if (settings != null) settingsArray = settings.split('###');
    if ($.inArray(settingName, settingsArray) === -1) {
        settingsArray.push(settingName);
    }
    const allSettings = settingsArray.join('###');
    createCookie('settings', allSettings, 30);
};

window.removeFromUserSettings = function (settingName) {
    const settings = readCookie('settings');
    if (settings == null) return;
    const updatedSettings = [];
    const settingsArray = settings.split('###');
    for (let i = 0; i < settingsArray.length; i++) {
        if (settingsArray[i] != settingName) {
            updatedSettings.push(settingsArray[i]);
        }
    }
    if (updatedSettings.length > 0) {
        createCookie('settings', updatedSettings.join('###'), 30);
    } else {
        eraseCookie('settings');
    }
};

window.isUserTypeTeacher = function () {
    if (userType != null && userType != '') return (userType == 'teacher');
    if (readCookie('v') != null) return (readCookie('v') == 'teacher');
    return false;
};

// setUpUserCookies should run before anything else since user data is used in tracking, react components and other javascript code
setUpUserCookies();
// it's duplicative, but  sometimes we want the body set before userCookiesReset is triggered
$(window).on('load', function () { setUpUserCookies(); });
