/*
 * From http://www.nczonline.net/blog/2010/09/07/learning-from-xauth-cross-domain-localstorage/
 * Relies on native localStorage; this script plus html/localStorage/server.html adds a cross-domain workaround
 * which always stores at secure. but can be retrieved from any donorschoose.org subdomain
 *
 * localStorage only takes key/value strings
 * callback required to get back values
 * ttls are in minutes
 *
 * All functions require lscache.js.
 */

// Declares window.lscache
import './lscache/lscache.js';

(function () {
    function CrossDomainLocalStorage() {
        /* note that donorschoose.org uses a slash at the end, postMessage's domain does not */
        this.origin = secureBaseURL.substring(0, secureBaseURL.length - 1);
        this.path = '/localstorage/server.html';
        this._iframe = null;
        this._iframeReady = false;
        this._queue = [];
        this._requests = {};
        this._id = 0;
    }

    CrossDomainLocalStorage.prototype = {

        // restore constructor
        constructor: CrossDomainLocalStorage,

        // public interface methods

        init: function () {
            const that = this;

            if (!this._iframe) {
                if (window.postMessage && window.JSON && window.localStorage) {
                    this._iframe = document.createElement('iframe');
                    this._iframe.style.position = 'absolute';
                    this._iframe.style.width = '1px';
                    this._iframe.style.height = '1px';
                    this._iframe.style.left = '-9999px';
                    document.body.appendChild(this._iframe);

                    if (window.addEventListener) {
                        this._iframe.addEventListener('load', function () { that._iframeLoaded(); }, false);
                        window.addEventListener('message', function (event) { that._handlePostMessage(event); }, false);
                    } else if (this._iframe.attachEvent) {
                        this._iframe.attachEvent('onload', function () { that._iframeLoaded(); }, false);
                        window.attachEvent('onmessage', function (event) { that._handlePostMessage(event); });
                    }
                } else {
                    throw new Error('Unsupported browser.');
                }
            }

            this._iframe.src = this.origin + this.path;
        },

        setValue: function (key, value, ttl) {
            window.lscache.set(key, value, ttl);
        },

        getValue: function (key) {
            return window.lscache.get(key);
        },

        getHistoryData: function () {
            const key = history.getState().data.key;
            if (key) {
                const data = this.getValue(key);
                if (data) {
                    return data;
                }
            }
            return {};
        },

        deleteHistoryData: function () {
            const key = history.getState().data.key;
            if (key) {
                this.setValue(key, null, 0);
            }
        },

        replaceHistory: function (data, title, params) {
            const key = window.location.pathname + params;
            this.setValue(key, data, 60);
            history.replaceState({ key }, title, params);
        },

        pushHistory: function (data, title, params) {
            const key = window.location.pathname + params;
            this.setValue(key, data, 60);
            history.pushState({ key }, title, params);
        },

        /**
         * TODO What does this do if `ttl` is not passed? No current callers pass one.
         */
        setCrossDomainValue: function (key, value, callback, ttl) {
            const request = {
                action: 'set',
                key,
                value,
                ttl,
                id: ++this._id,
            };
            const data = {
                request,
                callback,
            };

            if (this._iframeReady) {
                this._sendRequest(data);
            } else {
                this._queue.push(data);
            }

            if (!this._iframe) {
                this.init();
            }
        },

        /**
         * Request a value from localStorage and pass it to callback once it's fetched.
         *
         * Note that because we use lz-string to compress this value, it's automatically `JSON.parse`d when you fetch
         * it. So the value passed into `callback` could be a string _or_ an Object _regardless of what you passed in_.
         * @param key
         * @param callback
         */
        requestCrossDomainValue: function (key, callback) {
            const request = {
                action: 'get',
                key,
                id: ++this._id,
            };
            const data = {
                request,
                callback,
            };

            if (this._iframeReady) {
                this._sendRequest(data);
            } else {
                this._queue.push(data);
            }

            if (!this._iframe) {
                this.init();
            }
        },

        // private methods

        _sendRequest: function (data) {
            this._requests[data.request.id] = data;
            this._iframe.contentWindow.postMessage(JSON.stringify(data.request), this.origin);
        },

        _iframeLoaded: function () {
            this._iframeReady = true;

            if (this._queue.length) {
                for (let i = 0, len = this._queue.length; i < len; i++) {
                    this._sendRequest(this._queue[i]);
                }
                this._queue = [];
            }
        },

        _handlePostMessage: function (event) {
            if (event.origin == this.origin) {
                try {
                    const data = JSON.parse(event.data);
                    if (typeof this._requests[data.id].callback !== 'undefined' && this._requests[data.id].callback != null) this._requests[data.id].callback(data.key, data.value);
                    delete this._requests[data.id];
                } catch (e) {
                    // console.log(e.message);
                    // dcAnalytics.trackEventGA(["LocalStorage","errors", ((typeof event != "undefined" && typeof event.data != "undefined") ? event.data : "undefined") + ": " + e.message]);
                }
            }
        },

    };

    window.dcLocalStorage = new CrossDomainLocalStorage();
})();
