// WARNING duplicate file! If you are editing this (please don't), you'll have
// to edit the one in web/war/js/dc/require/adapters/dupes as well.

// Many of these functions are deprecated. Use https://github.com/derek-watson/jsUri methods instead.
// That library is also unmaintained, so maybe we should find something new

window.getQueryStringParams = function (query) {
	if (typeof query == "undefined" || query == null) return {};

	// new Uri(query) expects a full URL string, however the original version of getQueryStringParams
	// expected the query string after ? only -- to fix, check for ? and = and add back uri if necessary
	if (query.indexOf("=") > -1 && query.indexOf("?") == -1) {
		query = "?" + query;
	}

	var uri = new Uri(query);
	var params = {};
	uri.queryPairs.forEach(function (pair) {
		var key = pair[0];
		var val = pair[1];
		var currentValue = params[key];
		if (currentValue == undefined) {
			params[key] = val;
		} else if (Array.isArray(currentValue)) {
			currentValue.push(val);
		} else {
			params[key] = [currentValue, val];
		}
	});

	return params;
};

window.getQueryStringParamsBasedOnSplit = function (query) {
	var param = {};
	if (query == null) return param;
	if (query.indexOf("?") > -1) query = query.split("?")[1];
	var params = query.split("&");
	for (var i=0; i<params.length; i++) {
		var pos = params[i].indexOf('=');
		if (pos > 0) {
			var key = params[i].substring(0,pos);
			var val = params[i].substring(pos+1);
			param[key] = val;
		}
	}
	return param;
}

/**
 *  Note that an array is returned when two or more params with the same name are on the query string
 */
window.getQueryStringParam = function (query,param) {
	return getQueryStringParams(query)[param];
}

/**
 *  qsParam is restricted to single values
 */
window.qsParam = null;
if (window.location) {
	qsParam = getQueryStringParamsBasedOnSplit(window.location.search.substr(1));
}

/**
 * @deprecated
 * URL manipulation should be done with Uri.js
 */
window.removeQueryStringParam = function (query, param) {
	var prefix = encodeURIComponent(param)+'=';
	var pars = query.split(/[&;]/g);
	for (var i=pars.length; i-- > 0;) {
		if (pars[i].indexOf(prefix) == 0) {
			pars.splice(i, 1);
		}
	}
	if (pars.length > 0) return pars.join('&');
	else return "";
}

/**
 * @deprecated
 * URL manipulation should be done with Uri.js
 */
window.setQueryStringParam = function (query,param,newValue) {
	var currentParams = getQueryStringParams(query,"&");
	var currentValue = (currentParams != null) ? currentParams[param] : null;
	if (currentValue == null) return query + ((query != "") ? "&" : "") + param + "=" + newValue;
	else return query.replace(param + "=" + currentValue, param + "=" + newValue);
};

window.getURLParams = function (fullURL) {
	return getQueryStringParams(fullURL);
}

/**
 * Gets query string from URLs pushed using History.js,
 * whether the browser is HTML5 or has to fall back to hashes.
 * Hash fallbacks end up loading like dc.org/blah.html#blah.html?param=val
 * This will retrieve param=val
 * NOTE: This could conflict with using hashes to point to anchors
 */
window.getQueryStringForHistoryJS = function (fullURL) {
  var query = "";

  if (!fullURL) {
  	return query;
  }

  if (query.indexOf("#") > -1) {
  	query = query.split("#")[1];
  }

  if (fullURL.indexOf("?") > -1) {
  	query = fullURL.split("?")[1];
  }

  return query;
};

window.getURLParam = function (fullURL,param) {
	var queryParams = getURLParams(fullURL);
	return queryParams[param];
}

/**
 * @deprecated
 * URL manipulation should be done with Uri.js.
 * This won't work for multi-valued parameters
 */
window.setURLParam = function (fullURL,param,newValue) {
	var uri = fullURL;
	var query = "";
	var hash = "";
	if (uri.indexOf("#") > -1) {
		var uriArray = uri.split("#");
		uri = uriArray[0];
		hash = uriArray[1];
	}
    if (uri.indexOf("?") > -1) {
        var uriArray = uri.split("?");
        uri = uriArray[0];
        query = uriArray[1];
    }
    if (param != null && newValue != null) query = setQueryStringParam(query,param,newValue);
    else if (param != null) query = removeQueryStringParam(query,param);
    return uri + ((query != null && query.length > 0) ? "?" + query : "" ) + ((hash != null && hash.length > 0) ? "#" + hash : "");
}

/**
 * Sorts query params alphabetically by key.
 * Can be used to maximize cache hits.
 */
window.sortQueryParams = function (query) {
	if (query.indexOf("?") === 0) {
		query = query.substring(1);
	}
	var params = query.split("&");
	params.sort();
	return params.join("&");
}

/**
 * @returns {Boolean} Whether the URLs contain all
 * the same parameters and values, regardless of order.
 */
window.haveSameParams = function (url1, url2) {
	if (!url1 || !url2) return false;
	var sorted1 = sortQueryParams(getQueryStringForHistoryJS(url1));
	var sorted2 = sortQueryParams(getQueryStringForHistoryJS(url2));
	return sorted1 === sorted2;
};

window.haveSameSearchParams = function (url1, url2) {
	if (!url1 || !url2) return false;
	var sorted1 = sortQueryParams(stripOutAPISearchParams(url1));
	var sorted2 = sortQueryParams(stripOutAPISearchParams(url2));
	return sorted1 === sorted2;
}

// some search params are specific to api
window.stripOutAPISearchParams = function (url) {
	var apiParams=["concise","description"];
	var strippedURL = getQueryStringForHistoryJS(url);
	for (var i = 0; i < apiParams.length; i++) {
		strippedURL = setQueryStringParam(strippedURL,apiParams[i],null);
	}
	return strippedURL;
};

window.removeUTMParams = function (fullURL) {
	return new Uri(fullURL)
			.deleteQueryParam("utm_campaign")
			.deleteQueryParam("utm_medium")
			.deleteQueryParam("utm_source")
			.deleteQueryParam("utm_content")
			.deleteQueryParam("utm_term")
			.toString();
}

//TODO: Update this to use URI.js. It's too much work right now
window.getTrackingLink = function (fullURL, campaign, pageSource) {

	pageSource = pageSource || pageName;
	if (typeof pageSource == "undefined") {
		// pageName is set to the jsp name elsewhere, but in case that doesn't happen we are still providing a default
		pageSource = "unknown_page";
	};

	pageSource = pageSource.replace(/\s/g, '');
	campaign = campaign.replace(/\s/g, '_');

	var urlObj = new Uri(fullURL);

	urlObj.replaceQueryParam("utm_source","dc");
	urlObj.replaceQueryParam("utm_medium", campaign);
	urlObj.replaceQueryParam("utm_campaign",pageSource.toLowerCase());
	var cookieUserId = isNonBlankCookie("userId");
	if (isNonBlankCookie("userId") && typeof userId != "undefined" && userId) {
		if (typeof teacherId != "undefined" && parseInt(teacherId) == parseInt(userId)) {
			urlObj.replaceQueryParam("utm_term", "teacher_" + userId);
		} else {
			urlObj.replaceQueryParam("utm_term", "donor_" + userId);
		}
	} else {
		urlObj.replaceQueryParam("utm_term", "unknown");
	}

	return encodeURIComponent(setDefaultRFParamOnURL(urlObj.toString(), campaign, pageSource));
};

window.getParameterByName = function (name) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(location.search);
  return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

// use when setting webflow _eventId params
window.removeEventIdFromURLString = function (urlString) {
	var url = new Uri(urlString);
	url.deleteQueryParam('_eventId');
	var query = url.query().substr(1).split("&");
	for (var i = 0; i < query.length; i++) {
		if (query[i].indexOf("_eventId_") == 0) {
			url.deleteQueryParam(query[i]);
		}
	}
	return url.toString();
};
