window.dcQuickTimeout = 300;

// This is used in JSPs and such. Probably needs to stay global
window.trim = function (string) {
	return string.replace(/^\s+|\s+$/g, "");
};

// Modifying String.prototype will have to remain in a global file
// Should we convert it to a regular function?
String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

// Unused? It was added in 81d1f20cdd4a273e05f0aa91852c2649fdeff823 but not used there
String.prototype.lowerCaseFirstLetter = function() {
	return this.charAt(0).toLowerCase() + this.slice(1);
};

window.removeNonAlphaNumericChars = function (str) {
	return (str.replace(/[^a-zA-Z0-9]/g,""));
};

window.disableEnterKey = function (e) {
	var key;
	if(window.event) {
		key = window.event.keyCode;     //IE
	} else {
		key = e.which;     //firefox
	}
	return key != 13;
};

window.stripCommas = function (numString) {
	return (typeof numString == "string") ? numString.replace(/,/g, "") : numString;
};

window.dcParseInteger = function (numString) {
	return Math.round(stripCommas(numString));
};

window.isInteger = function (num) {
	return !isNaN(num) && (function(x) { return (x | 0) === x; })(parseFloat(num));
};

window.addCommas = function (nStr) {
	nStr += '';
	var x = nStr.split('.');
	var x1 = x[0];
	var x2 = x.length > 1 ? '.' + x[1] : '';
	var rgx = /(\d+)(\d{3})/;
	while (rgx.test(x1)) {
		x1 = x1.replace(rgx, '$1' + ',' + '$2');
	}
	return x1 + x2;
}

/**
 * Scrolls to top of the screen, or to the specified position
 * @param {number} [topPos=0] position to scroll to
 */
window.scrollToPageTop = function (topPos) {
    $(window.opera ? 'html' : 'html, body').animate({
        scrollTop: topPos || 0
    }, 300);
};

window.isEmailAddress = function (email) {
	var emailString = trim(email);
	if (emailString.indexOf("<") > -1 && emailString.indexOf("<") + 1 < emailString.length - 1)
		emailString = emailString.substring(emailString.indexOf("<") + 1,emailString.length - 1);
  var addressPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
  return addressPattern.test(emailString);
};

window.isValidEmail = function (email) {
	return isEmailAddress(email);
};

window.isValidFullName = function (fullName){
	return (trim(fullName) != "")
};

window.isValidZip = function (zip) {
	return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
};

window.isValidPhone = function (phone) {
	return /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/.test(trim(phone));
};

window.isValidPhoneWithExtension = function (phone) {
	return /^[2-9]\d{2}[. -]?[2-9]\d{2}[. -]?\d{4}/.test(phone);
};

window.isPositiveDollarAmount = function (amount) {
	if (amount == null || trim(amount) == "") {
		return false;
	}
	var donAmt = amount.replace(/[$ ,]/g,"");
	var regexp = /^[0-9.]+$/;
	return !(donAmt.match(regexp) == null || parseFloat(donAmt) <= 0);

};

window.isNonNegativeDollarAmount = function (amount) {
	if (amount == null || trim(amount) == "") {
		return false;
	}
	var donAmt = amount.replace(/[$ ,]/g,"");
	var regexp = /^[0-9.]+$/;
	return !(donAmt.match(regexp) == null || parseFloat(donAmt) < 0);

};

window.isAtLeastLength5 = function (zip) {
	return zip != null && zip.trim().length >= 5;
};

window.isAtMostLength20 = function (zip) {
	return zip != null && zip.trim().length <= 20;
};

window.isValidCreditCardNumber = function (cc) {
	if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cc)) { // visa
		return true;
	}
	if (/^5[1-5][0-9]{14}$/.test(cc)) { // mastercard
		return true;
	};
	if (/^3[47][0-9]{13}$/.test(cc)) { // amex
		return true;
	}
	if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cc)) { // discover
		return true;
	};
	return false;
}

window.isValidCVV2 = function (cvv2) {
	return /^[0-9]{3,4}$/.test(cvv2);
};

window.isAllCaps = function (text) {
	return !!(text && text.toUpperCase() == text);

};

String.prototype.reverse = function() {
 var res = "";
 for (var i = this.length; i > 0; --i) {
     res += this.charAt(i - 1);
 }
 return res;
};

window.setCursor = function (el,st,end) {
	if (el.setSelectionRange) {
		el.focus();
		el.setSelectionRange(st,end);
	} else if (el.createTextRange) {
		var range=el.createTextRange();
		range.collapse(true);
		range.moveend('character',end);
		range.moveStart('character',st);
		range.select();
	}
};

window.addLinkTimestamp = function (el) {
	var newLinkArray = el.href.split("#");
	var newLink = newLinkArray[0];
	if (newLink.indexOf("?") > -1) { newLink+= "&"; } else { newLink+= "?"; }
	newLink += (new Date()).getTime();
	if (newLinkArray[1] != null && newLinkArray[1] != "") newLink += "#" + newLinkArray[1];
	el.href = newLink;
};

window.getContentDocument = function (id) {
	var cd = null;
	if (document.getElementById(id) && document.getElementById(id).contentDocument) {
        cd = document.getElementById(id).contentDocument;
    } else if (document.getElementById(id) && document.getElementById(id).contentWindow) {
        cd = document.getElementById(id).contentWindow.document;
    } else if (window.frames[id]) {
        cd = window.frames[id].document;
    }
    return cd;
};

window.fitTextareaToContent = function (el) {
	el.style.height = "";
	el.style.height = (el.scrollHeight) + "px";
}


/***********************************************
* Textarea Maxlength script- Dynamic Drive (www.dynamicdrive.com)
* This notice must stay intact for legal use.
* Visit http://www.dynamicdrive.com/ for full source code
* (ADAPTED)
***********************************************/

window.enforceMaxLength = function (obj, maxLength){
	if (obj.value.length > maxLength) {
		obj.value = obj.value.substring(0,maxLength);
		if (obj.setSelectionRange) {
			obj.setSelectionRange(obj.value.length, obj.value.length);
		}
		else if (obj.createTextRange) {
			var range = obj.createTextRange();
			range.moveStart('character', obj.value.length);
			range.select();
		}
	}
};

window.returnFalse = function () {
	if (typeof event != "undefined" && event != null) {
		if (event.preventDefault)
			event.preventDefault();
		else
			event.returnValue = false;
	}
	return false;
};

window.formatCurrency = function (num,leaveOff0Cents) {
	num = num.toString().replace(/\$|,/g,'');
	if(isNaN(num))
		num = "0";
	var sign = (num == (num = Math.abs(num)));
	num = Math.floor(num*100+0.50000000001);
	var cents = num%100;
	num = Math.floor(num/100).toString();
	if(cents<10)
		cents = "0" + cents;
	for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
		num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
	return (((sign)?'':'-') + '$' + num + ((leaveOff0Cents && cents == "00") ? '' : '.' + cents));
};

window.currencyToFloat = function (num) {
	if (typeof num == "undefined" || num == null) return 0.00;
	num = num.toString().replace(/\$|,/g,'');
	return Number(parseFloat(num).toFixed(2));
};

window.stringToInt = function (num) {
	if (typeof num == "undefined" || num == null) return 0;
	num = num.toString().replace(/\$|,/g,'');
	return Number(parseInt(num));
};

window.getDocHeight = function () {
    var D = document;
    return Math.max(
        Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
        Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
        Math.max(D.body.clientHeight, D.documentElement.clientHeight)
    );
};


window.supports_history_api = function () {
    return !!(window.history && history.pushState);
};


window.greaterThanMobileBreakpoint = function () {
	return $(window).width() > 640;
};

window.getObjectKeyLength = function (obj) {
	return Object.keys(obj).length;
};

window.getAvailableScreenHeight = function (elId,otherIds) {
	if (elId == null) return $(window).height();
	var availScreenHeight = $(window).height() - $(elId).offset().top;
	if (otherIds != null && otherIds.length > 0) {
		for (var i = 0; i < otherIds.length; i++) {
			availScreenHeight = availScreenHeight - $(otherIds[i]).outerHeight();
		}
	}
	return availScreenHeight;
};

window.hasJquery = function () {
	return typeof $ === "function";
};

window.getUniques = function (arr) {
	var a = [];
	for (var i=0, l=arr.length; i<l; i++) {
		if (a.indexOf(arr[i]) === -1 && arr[i] !== '') {
			a.push(arr[i]);
		}
	}
	return a;
};

window.getCamelCaseFromHyphenated = function (elId) {
	var dataIdArray = elId.split("-");
	if (dataIdArray && dataIdArray.length > 1) {
		for (var i = 1; i < dataIdArray.length; i++) {
			dataIdArray[i] = dataIdArray[i].capitalizeFirstLetter();
		}
	}
	return dataIdArray.join("");
};

window.getHyphenatedFromCamelCase = function (elId) {
	return elId.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

window.getIdFromName = function (elName) {
	// convert dot . to dash - and remove square brackets[]
	return elName.replace(/\./g, "-").replace(/\[(.*?)\]/g,"$1");
};

window.getNameFromId = function (elId) {
	return elId.replace(/-/g, ".").replace(/(\d+)/g,"[$1]");
};

window.removeHTMLTagsAndIEStyleNewLines = function (elText) {
	return elText.replace(/\u000D\u000A/g, "\u000A").replace(/<.*?>/g,"");
};

window.punctuateList = function (arr) {
	if (!arr || arr.length == 0) {
		return "";
	} else if (arr.length == 1) {
		return arr[0];
	}

	var phrase = "";
	var arrLength = arr.length;
	for (var i = 0; i < arrLength; i++) {
		phrase += arr[i];
		if (i == arrLength -2) {
			phrase += " and ";
		} else if (i < arrLength -1) {
			phrase += ", ";
		}
	}
	return phrase;
};

window.backgroundImageToURL = function (str) {
	if (!str) {
		return "";
	}
	return str.match(/\((.*?)\)/)[1].replace(/('|")/g,'');
};

window.dcFormatDate = function (medDate) {
	return medDate.getMonthName() + " " + medDate.getDate() + ", " + medDate.getFullYear();
};

window.formatPhoneNumber = function (number) {
	return number && number.replace(/^1?(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
};

window.isValidPhoneNumber = function (number) {
	return number && number.replace(/\D/g, '').length === 10;
};

window.formatTitleCase = function (str) {
	return str && (str.replace(/\w\S*/g, function(txt){
				return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
			}));
};

window.getTooLongMessage = function (maxEntities, entityType) {
	return "<span>Too long! Please edit it down to under " + maxEntities + " " + entityType + "s.</span>";
};

window.serializeJQueryForm = function (elId) {
	var o = {};
	var a = $(elId).serializeArray();
	$.each(a, function() {
		var val = this.value || "";
		if (val == "on") {
			val = true;
		} else if (val == "false" || val == "true") {
			val = (val == "true");
		} else if (Number.isInteger(val)) {
			val = parseInt(val);
		}
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(val);
		} else {
			o[this.name] = val;
		}
	});

	// note that checkbox off will be missing, so add this back
	var checkboxes = $(elId).find("input:checkbox:not(:checked)");
	for (var i=0; i < checkboxes.length; i++) {
		var el = checkboxes[i];
		if (o[el.name] !== undefined) {
			if (!o[el.name].push) {
				o[el.name] = [o[el.name]];
			}
			o[el.name].push(false);
		} else {
			o[el.name] = false;
		}
	}

	return o;
};
