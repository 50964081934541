// Used in thirdparty-general.js, so export everything globally

// main buckets split by - (dash)
// use _ (underscore) as word separators
window.getRFParamDelimiter = function () {
    return '-';
};

/**
 * @param {string} anyURLQueryOrHash pass in "" for query if you just want to rebuild an rf param from scratch
 * @param {string} campaign Campaign name
 * @param {number=} creatorId Teacher ID to use (useful if this isn't a page where the global teacherId is set
 */
window.getDefaultRFParamValue = function (anyURLQueryOrHash, campaign, pageSource, creatorId) {
    let rfString = getRfParamFromQueryStringOrHash(anyURLQueryOrHash);

    // rebuild from scratch
    if (rfString == null || rfString == '') {
        const rfDate = new Date();
        let rfMonth = rfDate.getMonth() + 1;
        if (rfMonth < 10) rfMonth = '0' + rfMonth;
        rfString = campaign + getRFParamDelimiter() + 'dc' + getRFParamDelimiter() + rfDate.getFullYear() + '-' + rfMonth;

        // add pageName if available
        let trailingCampaign;
        if (pageSource) {
            trailingCampaign = (pageSource.toLowerCase()).replace(/\W+/g, '_');
            if (rfString.indexOf(trailingCampaign) == -1) {
                rfString += getRFParamDelimiter() + trailingCampaign;
            }
        } else if (pageName && rfString.split('-').length <= 4) {
            trailingCampaign = (pageName.toLowerCase()).replace(/\W+/g, '_');
            if (rfString.indexOf(trailingCampaign) == -1) {
                rfString += getRFParamDelimiter() + trailingCampaign;
            }
        }

        if (!creatorId && isTeacherUserPage()) {
            creatorId = teacherId;
        }

        // add teacherid
        if (isCurrentUser(creatorId) && rfString.indexOf('teacher_' + creatorId) == -1) {
            rfString += getRFParamDelimiter() + 'teacher_' + creatorId;
        }
        // add donorid
        else if (isNonBlankCookie('userId') && typeof userId !== 'undefined' && userId != '') {
            rfString += getRFParamDelimiter() + 'donor_' + userId;
        }
        // add unknown
        else {
            rfString += getRFParamDelimiter() + 'unknown';
        }
    }

    return rfString;
};

window.getRfParamFromQueryStringOrHash = function (anyURLQueryOrHash) {
    let hash = '';
    let query = anyURLQueryOrHash;
    if (anyURLQueryOrHash.indexOf('#') > -1) {
        query = anyURLQueryOrHash.split('#')[0];
        hash = anyURLQueryOrHash.split('#')[1];
    }
    if (query.indexOf('?') > -1) {
        query = query.split('?')[1];
    }
    // first look for rf param on the query string
    let rfString = getQueryStringParam(query, 'rf');
    // then try the hash string
    if (rfString == null || rfString == '') rfString = getQueryStringParam(hash, 'rf');

    if (rfString && rfString.constructor === Array) {
        rfString = rfString[0];
    }

    return (rfString != null && rfString != '') ? rfString.replace(/[^a-zA-Z0-9-_]/g, '') : '';
};

window.addTeacherChallengeIdParamBasedOnRFParam = function (queryString) {
    const teacherId = getRfParamTeacherId(queryString);
    const challengeId = getQueryStringParam(queryString, 'challengeid');
    if (!challengeId && teacherId && userObj.userId == teacherId && userObj.teacherChallengeId) {
        queryString = setQueryStringParam(queryString, 'challengeid', userObj.teacherChallengeId);
    }
    return queryString;
};

window.setDefaultRFParamOnQuery = function (query, campaign, pageSource) {
    const rfString = getDefaultRFParamValue(query, campaign, pageSource);
    return addTeacherChallengeIdParamBasedOnRFParam(setQueryStringParam(query, 'rf', rfString));
};

window.setDefaultRFParamOnURL = function (fullURL, campaign, pageSource) {
    const rfString = getDefaultRFParamValue(fullURL, campaign, pageSource);
    return addTeacherChallengeIdParamBasedOnRFParam(setURLParam(fullURL, 'rf', rfString));
};

window.setRebuiltRFParamOnQuery = function (query, campaign, pageSource) {
    const rfString = getDefaultRFParamValue('', campaign, pageSource);
    return addTeacherChallengeIdParamBasedOnRFParam(setQueryStringParam(query, 'rf', rfString));
};

window.setRebuiltRFParamOnURL = function (fullURL, campaign, pageSource) {
    const rfString = getDefaultRFParamValue('', campaign, pageSource);
    return addTeacherChallengeIdParamBasedOnRFParam(setURLParam(fullURL, 'rf', rfString));
};

window.getRfParamTeacherId = function (rfParamString) {
    let rfParam = getRfParamFromQueryStringOrHash(location.href);
    if (rfParamString) rfParam = getRfParamFromQueryStringOrHash(rfParamString);
    if ((rfParam == null || rfParam == '') && readCookie('rfParam') != null) rfParam = readCookie('rfParam');
    if (rfParam != null && rfParam.indexOf('teacher_') > -1) {
        const rfParamTeacherIdArray = rfParam.split('teacher_');
        for (let i = 0; i < rfParamTeacherIdArray.length; i++) {
            let rfParamTeacherId = rfParamTeacherIdArray[i];
            if (rfParamTeacherId.indexOf('-') > -1) {
                rfParamTeacherId = (rfParamTeacherId.split('-')[0]);
            }
            rfParamTeacherId = parseInt(rfParamTeacherId);
            if (!isNaN(rfParamTeacherId) && rfParamTeacherId > 0) {
                return rfParamTeacherId;
            }
        }
    }
    return null;
};

window.isTeacherUserPage = function () {
    return (typeof teacherId !== 'undefined' && isCurrentUser(teacherId));
};

/**
 * @param {number} testUserId The ID to test against
 * @return {boolean} Whether the current user matches the given userId
 */
window.isCurrentUser = function (testUserId) {
    return userId != null && userId != '' && testUserId == userId;
};
