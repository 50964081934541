// Wrapping this in an IIFE just to ensure that newly-extracted functions won't be public unless we need them to be.
// Used in thirdparty-general.js, so be careful about removing global exports
(function () {
    /*
     * landingurl
     * rfParam
     * referrer
     * created -- stored as utc milliseconds string
     * firstInSession
     */

    function isInterestingReferrer(referrer) {
        if (typeof referrer === 'undefined' || referrer == null || referrer == '') return false;

        const uninterestingReferrers = ['localhost',
            '.donorschoose.org',
            'https://www.paypal.com',
            '/cgi-bin/webscr?cmd=_flow',
            'https://authorize.payments.amazon.com'];

        for (let i = 0; i < uninterestingReferrers.length; i++) {
            if (referrer.indexOf(uninterestingReferrers[i]) > -1) {
                return false;
            }
        }
        return true;
    }

    function getSessionURLValues() {
        return getCookiedURLValues(readCookie('sessionURL'));
    }

    function getCookiedURLValues(urlString) {
        if (urlString == null || urlString == '') return {};
        const urlStringArray = urlString.split('###');
        if (urlStringArray.length > 0) {
            const referrer = urlStringArray[0];
            const landingURL = (urlStringArray[1] != null) ? unescape(urlStringArray[1]) : null;
            const created = (urlStringArray[2] != null) ? unescape(urlStringArray[2]) : null;
            return { referrer, landingURL, created };
        }
        return {};
    }

    let rsvTimeout;
    function getReferredSiteVisitForm(referredSiteVisitsString, uuid) {
        // For local servers only, post size is limited to about 4K
        // so take the last 10 if we're getting too close
        if (baseURL.indexOf('local') > -1 && referredSiteVisitsString.length > 4000) {
            var referredSiteVisits = JSON.parse(referredSiteVisitsString);
            referredSiteVisitsString = JSON.stringify(referredSiteVisits.slice((referredSiteVisits.length - 10)));
        }
        // max 50K characters in post, so try to respect that limit by sending back no more than 250 items
        else if (baseURL.indexOf('local') == -1 && referredSiteVisitsString.length > 50000) {
            var referredSiteVisits = JSON.parse(referredSiteVisitsString);
            if (referredSiteVisits.length > 250) {
                const truncated = new Array(referredSiteVisits[0]);
                truncated.concat(referredSiteVisits.slice((referredSiteVisits.length - 249)));
                referredSiteVisitsString = JSON.stringify(truncated);
            }
        }

        $('#referredSiteVisitForm').remove();
        const rsvFormAction = secureBaseURL + 'common/referredSiteVisits.html';
        let rsvFormString = '<form class="hidden" method="post" name="referredSiteVisitForm" id="referredSiteVisitForm" action="' + rsvFormAction + '">';
        rsvFormString += '<input type="hidden" name="referredSiteVisits" value="" />';
        rsvFormString += '<input type="hidden" name="callback" value="saveReferredSiteVisitCallback" />';
        rsvFormString += '<input type="hidden" name="postMessage" value="parent.postMessage" />';
        if (uuid) {
            rsvFormString += `<input type="hidden" name="uuid" value="${uuid}" />`;
        }
        rsvFormString += '</form>';

        const formEl = $(rsvFormString)[0];
        formEl.elements.referredSiteVisits.value = referredSiteVisitsString;
        document.body.appendChild(formEl);

        // if we don't hear back from the server in 30 seconds, delete referredSiteVisit records and don't try again
        rsvTimeout = setTimeout(function () { console.log('Unresponsive server: referredSiteVisit records deleted.'); dcLocalStorage.setCrossDomainValue('referredSiteVisits', '[]'); }, (1000 * 30));
        return formEl;
    }

    function saveReferredSiteVisitCallback(response) {
        clearTimeout(rsvTimeout);
        if (response && response.data && response.data.success) {
            dcLocalStorage.setCrossDomainValue('referredSiteVisits', '[]');
        }
    }

    /**
     * Shared code to submit a referredSiteVisits array to ReferredSiteVisitUpdateController.
     *
     * Extracted from logReferredSiteVisit.
     * @param referredSiteVisits
     * @param uuid Optional string UUID referring to an emailcontactsignup with which this visit should be associated
     */
    const syncReferredSiteVisits = (referredSiteVisits, uuid) => {
        /* SAFARI hack:
         * Cross-domain localStorage iframe no longer works because safari is checking
         * parent subdomain and storing there.  As a fallback, use cookies to populate values.
         */
        if (referredSiteVisits.length === 0) {
            const referringRSV = getRSVFromCookiedVisit('referringURL');
            if (referringRSV != null) referredSiteVisits.push(referringRSV);
            const sessionRSV = getRSVFromCookiedVisit('sessionURL');
            if (sessionRSV != null && (referringRSV == null || sessionRSV.created != referringRSV.created)) referredSiteVisits.push(sessionRSV);
        }

        setSaveAttemptedForCookiedVisit('referringURL');
        setSaveAttemptedForCookiedVisit('sessionURL');

        if (referredSiteVisits.length > 0) {
            const referredSiteVisitsString = JSON.stringify(referredSiteVisits);
            const formEl = getReferredSiteVisitForm(referredSiteVisitsString, uuid);
            submitFormToIframe(formEl, 'referredSiteVisitFormIframe');
        }
    };

    /**
     * Normally, we conditionally sync to `referredsitevisit` when pages are loaded. If you want to imperatively sync
     * instead, call this function to try to send whatever's in localStorage to the server.
     */
    const forceSyncReferredSiteVisits = (uuid) => {
        dcLocalStorage.requestCrossDomainValue('referredSiteVisits', function (key, value) {
            if (value == null || value == '') {
                // No referredSiteVisits in localStorage; nothing to sync
                return;
            }

            syncReferredSiteVisits(value, uuid);
        });
    };

    function logReferredSiteVisit(genLandingURL) {
        const landingURL = genLandingURL;
        let rfParam = getRfParamFromQueryStringOrHash(genLandingURL);

        // ignore rfParam if it looks like a teacher is referring themselves
        if (rfParam != null && rfParam.indexOf('teacher_') > -1 && userId != null && userId != '' && userId == getRfParamTeacherId(genLandingURL)) {
            rfParam = '';
        }

        const referrer = (typeof document.referrer !== 'undefined' && document.referrer != null) ? document.referrer : '';
        let firstInSession = false;

        // get date UTC
        const created = new Date();
        const createdMills = created.getTime();

        const rsv = { landingURL, rfParam, referrer, firstInSession: firstInSession.toString(), created: createdMills.toString() };

        // As of approx July 10 2013, always write the referringURL and sessionURL cookies even if we think there isn't a valid referrer

        // first ever visit
        if (readCookie('referringURL') == null) {
            firstInSession = true;
            rsv.firstInSession = 'true';
            var locString = escape(referrer) + '###' + escape(landingURL) + '###' + createdMills;
            createCookie('referringURL', locString, 45);
            // alert(locString);
        }

        // first in session
        if (readCookie('sessionURL') == null) {
            firstInSession = true;
            rsv.firstInSession = 'true';
            var locString = escape(referrer) + '###' + escape(landingURL) + '###' + createdMills;
            createCookie('sessionURL', locString, 0);
            // alert("session" + locString);
        }

        // double check that it's not just a page reload
        const savedRfParam = readCookie('rfParam');
        const currentSessionURL = getSessionURLValues();

        const hasNewReferredSiteVisit = (firstInSession || (rfParam != '' && (savedRfParam == null || savedRfParam != rfParam)) || (isInterestingReferrer(referrer) && referrer != currentSessionURL.referrer));

        dcLocalStorage.requestCrossDomainValue('referredSiteVisits', function (key, value) {
            // Note that dcLocalStorage.requestCrossDomainValue will attempt deserialization on its own. So `value` could
            // be either a string or an array even though we only ever *insert* strings.
            const referredSiteVisits = (value != null && value != '') ? value : [];
            if (hasNewReferredSiteVisit) {
                referredSiteVisits.push(rsv);
                dcLocalStorage.setCrossDomainValue('referredSiteVisits', JSON.stringify(referredSiteVisits));
            }
            // write to db if we think the person already has a jsessionid and a userid

            const hasCartInteraction = (isNonBlankCookie('cartUUID') || isNonBlankCookie('cartGiftCodeAmount')) && userId;
            const isTeacherOnSecurePage = (userId && userObj && userObj.userType == 'teacher' && (window.location.hostname).indexOf('secure') > -1);

            if (isLoggedIn() || isTeacherOnSecurePage || (hasCartInteraction)) {
                syncReferredSiteVisits(referredSiteVisits);
            }
            if (rfParam != '') createCookie('rfParam', rfParam, 0);
        });
    }

    function getRSVFromCookiedVisit(cookieType) {
        if (readCookie(cookieType) != null) {
            const rsvArray = readCookie(cookieType).split('###');
            if (rsvArray.length == 3) {
                const landingURL = unescape(rsvArray[1]);
                let rfParam = '';
                const landingURLArray = landingURL.split('rf=');
                if (landingURLArray.length > 1) {
                    rfParam = (landingURLArray[1].split('&'))[0];
                }
                return {
                    landingURL,
                    rfParam,
                    referrer: unescape(rsvArray[0]),
                    firstInSession: 'true',
                    created: rsvArray[2],
                };
            }
        }
        return null;
    }

    function setSaveAttemptedForCookiedVisit(cookieType) {
        if (readCookie(cookieType) != null) {
            const rsvArray = readCookie(cookieType).split('###');
            if (rsvArray.length == 3) {
                rsvArray.push('attemptedSave');
                createCookie(cookieType, rsvArray.join('###'), (cookieType == 'sessionURL') ? 0 : 45);
            }
        }
    }

    function setLastVisitedPage() {
        createCookie('lastVisitedPage', escape(location.href), 1);
    }

    $(document).ready(function () {
        setLastVisitedPage();
        if (window.genLandingURL) {
            logReferredSiteVisit(window.genLandingURL);
        }
    });

    window.isInterestingReferrer = isInterestingReferrer;
    // Used for JSONP callback
    window.saveReferredSiteVisitCallback = saveReferredSiteVisitCallback;
    window.forceSyncReferredSiteVisits = forceSyncReferredSiteVisits;
})();
