// These functions are used in JSPs and JS
window.isLoggedIn = function () {
    const loggedInCookie = readCookie('isLoggedIn');
    return loggedInCookie === 'true';
};

// This can be used for show and hide but there needs to be back end code to do a real auth check
window.isLoggedInOrRemembered = function () {
    const loggedInOrRemembered = readCookie('headerinfo');
    return !(loggedInOrRemembered == null || loggedInOrRemembered === '');
};
