// Used in thirdparty-general.js, so be careful about removing global exports

window.createCookieWithSameSiteNone = function (name, value, days) {
    createCookieWithMillisTimeout(name, value, days && days * 24 * 60 * 60 * 1000, 'None');
};

window.createCookie = function (name, value, days) {
    createCookieWithMillisTimeout(name, value, days && days * 24 * 60 * 60 * 1000);
};

window.createCookieWithMillisTimeout = function (name, value, millis, sameSite) {
    let expires = '';
    if (millis && !isNaN(millis)) {
        const date = new Date();
        expires = '; expires=';
        date.setTime(date.getTime() + millis);
        expires = '; expires=' + date.toGMTString();
    }
    const sameSiteValues = ['strict', 'lax', 'none'];
    if (typeof sameSite === 'string' && sameSiteValues.includes(sameSite.toLowerCase())) {
        expires += '; SameSite=' + sameSite;
    }
    let domain = '';
    if (window.location.host.indexOf('donorschoose.org') != -1) {
        domain = '; domain=donorschoose.org';
    }
    document.cookie = name + '=' + value + expires + ';secure; path=/' + domain;
};

window.readCookie = function (name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) {
            let cookieString = c.substring(nameEQ.length, c.length);
            if (cookieString.charAt(0) === '"' && cookieString.charAt(cookieString.length - 1) === '"') {
                cookieString = cookieString.substring(1, cookieString.length - 1);
            }
            return cookieString;
        }
    }
    return null;
};

window.findCookie = function (namePatternString) {
    const ca = document.cookie.split(';');
    const patt = new RegExp(namePatternString);
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        c = c.split('=')[0];
        if (patt.test(c)) {
            return c;
        }
    }
    return null;
};

window.isNonBlankCookie = function (name) {
    const cookie = readCookie(name);
    return (cookie != null && cookie != '');
};

window.eraseCookie = function (name) {
    createCookie(name, '', -1);
};

$(document).ready(function () {
    if (qsParam.challengeid != null) {
        const isOffPage = qsParam.offpage != null;
        const isTeacherReferred = getRfParamTeacherId() != null;
        if (isOffPage || isTeacherReferred) {
            createCookie('challengeId', qsParam.challengeid, 2);
        }
    } else if (qsParam.partnerId != null) {
        createCookie('challengeId', qsParam.partnerId, 2);
    }
});

(function () {
    const suspectedFirstTimeUser = (readCookie('hasCookies') == null);
    createCookieWithSameSiteNone('hasCookies', 'true', 0);

    // #checkCookiesAlert created in checkJavascript.jsp
    $(document).ready(function () {
        if (suspectedFirstTimeUser && typeof dcAnalytics !== 'undefined' && !userId && (!document.referrer || isInterestingReferrer(document.referrer))) {
            dcAnalytics.track('suspectedFirstTimeUser');
            createCookie('suspectedFirstTimeUser', (new Date()).getTime(), 0);
            console.log('reached suspectedFirstTimeUser');
        }

        if (readCookie('hasCookies') == null && document.getElementById('checkCookiesAlert')) {
            document.getElementById('checkCookiesAlert').style.display = 'block';
            if (document.getElementById('table1')) document.getElementById('table1').style.display = 'none';
            if (document.getElementById('mainBody')) document.getElementById('mainBody').style.display = 'none';
            if (document.getElementById('mainHeader')) document.getElementById('mainHeader').style.display = 'none';
            if (document.getElementById('mainFooter')) document.getElementById('mainFooter').style.display = 'none';
        }
        $('.js-reload-page').on('click', function (e) {
            window.location.reload();
            e.preventDefault();
        });
    });
})();
